
    (function(){
      var _fontawesome = require('@fortawesome/fontawesome-svg-core');
      var pack;
      
        pack = require('@fortawesome/free-solid-svg-icons'); 
        delete pack['default']; 
        _fontawesome.library.add(pack);

        pack = require('@fortawesome/free-regular-svg-icons'); 
        delete pack['default']; 
        _fontawesome.library.add(pack);

        pack = require('@fortawesome/free-brands-svg-icons'); 
        delete pack['default']; 
        _fontawesome.library.add(pack);
    })()
  